class subtitles {
    //物件建立時產生
    constructor(){
        // this.navData = {
        //     sub_tile:'儀表板',
        //     isBack:false,
        //     acvtion:[]
        // }
    }

    setSubTitleObj(data,ary){
        ary.acvtion = []
        ary.isBack = false
        ary.isLink = null
        switch (data.name) {
            case 'dashboard':
                ary.sub_tile = '儀表板'
                break;
            case 'account-list':
                ary.sub_tile = '帳號管理'
                ary.acvtion = [
                    {
                      name:'建立',
                      icon:'fa-plus-circle',
                      to:'/account/add'
                    }
                  ]
                 break;
            case 'account-info':
                ary.sub_tile = '帳號資訊'
                ary.isBack = true
                break;
            case 'account-add':
                ary.sub_tile = '建立帳號'
                ary.isBack = true
                break;
            case 'account-edit':
                ary.sub_tile = '編輯帳號'
                ary.isBack = true
                break;
            case 'clinic-list':
                ary.sub_tile = '診所管理'
                ary.isBack = true
                break;
            case 'clinic-info':
                ary.sub_tile = '詳細資訊'
                ary.isBack = true
                break;
            case 'order-list':
                ary.sub_tile = '訂單管理'
                break;
            case 'order-info':
                ary.sub_tile = '訂單詳細資訊'
                ary.isBack = true
                break;
            case 'order-con':
                ary.sub_tile = '檢視訂單'
                ary.isBack = true
                break;
            case 'assign-list':
                ary.sub_tile = '指名訂單管理'
                break;
            case 'match-list':
                ary.sub_tile = '媒合管理'
                break;
            default:
              ary.sub_tile = 'subtitles.js 未設定'
              break;
        }
        return ary
    }
}
export default subtitles