const orderRoutes = [
    {
        path:'list',
        name:'order-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/order/list.vue')
    },
    {
        path:'info',
        name:'order-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/order/info.vue')
    },
    {
        path:'con',
        name:'order-con',
        component: () => import(/* webpackChunkName: "about" */ '../views/order/con.vue')
    },
]
export default orderRoutes