import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router/index'
import { createStore } from 'vuex'
import stores from './store/index'
const router = createRouter({
    history: createWebHistory(),
    routes,
})
const store = createStore(stores)
const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
