const clinicRoutes = [
    {
        path:'list',
        name:'clinic-list',
        component: () => import(/* webpackChunkName: "about" */ '../views/clinic/list.vue')
    },
    {
        path:'info',
        name:'clinic-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/clinic/info.vue')
    },
    

]
export default clinicRoutes