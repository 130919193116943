import accountRoutes from './accountRoutes'
import clinicRoutes from './clinicRoutes'
import orderRoutes from './orderRoutes'
import assignRoutes from './assignRoutes'
import matchRoutes from './matchRoutes'
const routes = [
    {
        path:'/',
        name:'dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
    },
    {
        path:'/order',
        name:'order',
        children:orderRoutes
    },
    {
        path:'/assign',
        name:'assign',
        children:assignRoutes
    },
    {
        path:'/match',
        name:'match',
        children:matchRoutes
    },
    {
        path:'/clinic',
        name:'clinic',
        children:clinicRoutes
    },
    {
        path:'/account',
        name:'account',
        children:accountRoutes
    },
]
export default routes